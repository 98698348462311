.view {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .rowWrap {
    padding-bottom: 40px;
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
.optionReport{
    width: 231px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    border-radius: 6px;
    font-size: 13px;
    box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
    cursor: pointer;
}

.reporte{
    color: #585858;
    background-color: white;
}

.reportSelect{
    color: white;
    background-color: #8FD400;
}

.card{
    background-color: white;
    box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
    border-radius: 6px;
}

.cuadrante {
    display: grid;
    grid-gap: 0em;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "unoseis dosseis tresseis"
    "cuatroseis cincoseis seisseis";
    font-size: 20px;
    font-weight: lighter;
    color: #8FD400;
    align-items: center;
    justify-items: center;
}

.cuadrante_dos{
    display: grid;
    grid-gap: 0em;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: "unoseis dosseis tresseis cuatroseis";
    font-size: 20px;
    font-weight: medium;
    color: #8FD400;
    align-items: center;
    justify-items: center;
}

.unoseis {
    grid-area: unoseis;
    border: 0.5px solid #D6D6D6;
    justify-self: stretch;
    height: 100%;
    width: 100%;
}

.dosseis { 
    grid-area: dosseis;
    border: 0.5px solid #D6D6D6;
    justify-self: stretch;
    height: 100%;
    width: 100%;
}

.tresseis { grid-area: tresseis;
    border: 0.5px solid #D6D6D6;
    justify-self: stretch;
    height: 100%;
    width: 100%;
}

.cuatroseis { grid-area: cuatroseis;
    text-align: center;
    border: 0.5px solid #D6D6D6;
    justify-self: stretch;
    height: 100%;
    width: 100%;}

.cincoseis { grid-area: cincoseis;
    text-align: center;
    border: 0.5px solid #D6D6D6;
    justify-self: stretch;
    height: 100%;
    width: 100%;}

.seisseis { grid-area: seisseis;
    text-align: center;
    border: 0.5px solid #D6D6D6;
    justify-self: stretch;
    height: 100%;
    width: 100%;}

.boxdash_container {
    width: 100%;
    height: 100%;
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;
}

.indicador{
    font-size: 56px;
    color: #8FD400;
}

.contenedor_rr_kpi{
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.indicador_secondary{
    font-size: 68px;
    color: #585858;
    line-height: 1;
}

.sub_indicador_primary {
    color: #8FD400;
    font-size: 34px;
    line-height: 1.1;
}

.img_sub_indicador_primary{
    line-height: 2.1;
}

.img_sub_indicador_danger{
    line-height: 2.1;
}

.sub_indicador_danger {
    color: #FC4850;
    margin-top: 1em;
    font-size: 34px;
    line-height: 1.1;
}

.contenedor_desc{
    width: 65%;
    margin: 0 auto;
}

.desc_indicador{
    font-size: 14px;
    color: #585858;
}

.font_size_36{
    font-size: 36px;
}

.d_Flex{
    display: flex;
    justify-content: center;
}



.kpi_variacion{
    align-items: b;
}

.titulo_reportes{
    font-family: Bree;
    font-size: 32px;
    color: #8FD400;
    margin-bottom: 36px;
}

.filtro_general{
    font-size: 14px;
    color: #7E7E7E;
    padding-left: 1rem;
    padding-right: 1rem;
}

.filtro_seleccionado{
    font-size: 14px;
    color: #8FD400;
    border-bottom: 3px solid #8FD400;
    padding-left: 1rem;
    padding-right: 1rem;
}


.mb_10{
    margin-bottom: 10px;
}

.mr_2{
    margin-right: 2px;
}

.mr_5{
    margin-right: 5px;
}

.mr_8{
    margin-right: 8px;
}

.justify_content_end{
    justify-content: end;
}



.contenedor_filtros{
    justify-items: center;
    align-items: center;
}

/*          Reporte Seleccionado            */

.active{
    color: white;
    background-color: #8FD400;
}

.no_active{
    color: #585858;
    background-color: white;
}

.font_weight_14{
    font-weight: 14;
}

.font_weight_16{
    font-weight: 16;
}


.cien_por_cien{
    width: 100%;
}


/*             Padding & Margin              */
/*===========================================*/
.margin_1{
    margin: 1;
}

.margin_10{
    margin: 10px;
}

.margin_botton_10{
    margin-bottom: 10px;
}

.margin_botton_titles_reports{
    margin-bottom: 36px;
}

.margin_top_10{
    margin-top: 10px;
}

.margin_left_10{
    margin-left: 10px;
}

.margin_right_10{
    margin-right: 10px;
}

.area_chart_pie{
    margin: 4em;
}

.margin_4em{
    margin-bottom: 4em;
    margin-left: 4em;
}


.titulo_char_top{
    margin-left: 0.5em;
    margin-top: 0.5em;
    font-size: 24px;
    color: #444444;
}

.circles_kpi{
    font-family: Bree;
    width: 215px;
    height: 215px;
    color: #8FD400;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 50%;
    margin: 2em auto;
    padding: 1em;
}

.circles_kpi_img {
    width: 30%;
    top: 0;
    margin: 0;
    padding: 0;
    height: 56px;
}

.font_siza_56_primary{
    font-size: 50px;
    color: #585858;
}

.circle_sub_title {
    font-size: 1.4em;
    width: 50%;
    margin: 0 auto;
    line-height: 1.2;
}
.aling_items_baseline{
    align-items: baseline;
}

/***********    UTILIDADES    ***********/
.cursor_pointer:hover{
    cursor: pointer;
}
