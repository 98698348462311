.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.toppingsView {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header h1 {
  color: var(--green);
  font-family: Bree;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 0;
}

.input {
  background-color: #FBFBFB;
  border-color: #D2D2D2;
  border-radius: 4px;
  width: 300px;
}

.submit {
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  margin-left: 20px;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.toppingsWrap {
  padding-bottom: 40px;
}