.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.edit {
  cursor: pointer;
}

.hoursBtn {
  margin-right: 20px;
  width: 200px;
  font-weight: 500;
  border-radius: 6px;
  font-size: 14px;
}
