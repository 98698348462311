.topping {
  background-color: white;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header span {
  font-family: Bree;
  font-weight: 700;
  color: var(--darkGray);
  font-size: 16px;
}

.options {
  margin-top: 20px;
  color: #383838;
}

.option {
  padding: 5px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  margin: 0 -5px 15px -5px;
  border-radius: 4px;
}

.option:hover {
  border-color: var(--green);
}

.option:hover .divider {
  background-color: #C9C9C9;
}

.option:hover .edit, .option:hover .trash {
  display: block;
}

.option:last-child {
  margin-bottom: 0;
}

.check {
  flex: 1;
  margin-right: 10px;
}

.divider {
  margin: 0 10px;
  width: 1px;
  height: 100%;
  background-color: transparent;
  height: 28px;
}

.edit, .trash {
  display: none;
  cursor: pointer;
}

.add {
  cursor: pointer;
}
