.view {
  position: relative;
  padding-bottom: 80px;
}

.newOrdersTable {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
}

.newOrdersTable .header {
  background-color: var(--darkGray);
  color: var(--green);
  font-family: Bree;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 18px;
}

.newOrdersTable .body {
  background-color: white;
  padding: 20px;
}

.newOrdersTable .row {
  display: flex;
  padding: 20px 0;
}

.newOrdersTable .tableHeader {
  padding-top: 0;
  padding-bottom: 5px;
  font-size: 12px;
  color: #585858;
}

.newOrdersTable .cellTicket,
.cellTime,
.cellAmount {
  width: 120px;
  text-align: center;
}

.newOrdersTable .cellCostumer {
  flex: 1;
}

.newOrdersTable .tableItem {
  border-top: 1px solid #c5c5c5;
  color: #444444;
  font-weight: 500;
  cursor: pointer;
}

.newOrdersTable .tableItem:last-child {
  padding-bottom: 0;
}

.newOrdersTable .amount {
  color: var(--green);
  font-weight: 700;
}

.activeOrders {
  margin-top: 40px;
}

.activeOrdersInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  padding-left: 15px;
}

.labelInfo {
  font-family: Bree;
  font-weight: 700;
  color: var(--darkGray);
}

.toHistory {
  color: var(--darkGray);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: underline;
}

.toHistory:hover {
  color: var(--darkGray);
  text-decoration: underline;
}

.iconClock {
  margin-left: 5px;
}

.card {
  background-color: white;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
  padding: 20px 10px;
  border-radius: 10px;
  display: flex;
  margin-bottom: 20px;
}

.card > span,
.card > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-family: Bree;
  font-weight: 700;
  font-size: 18px;
  height: 70px;
  color: var(--darkGray);
}

.valueCard {
  font-size: 26px !important;
  color: var(--green) !important;
  border-left: 1px solid #dedede;
  flex-direction: column;
}

.valueCard {
  display: block;
}

.value2 {
  margin-top: 5px;
  color: var(--darkGray);
}

.numberOrders {
  font-size: 32px !important;
}

.branches {
  margin-top: 40px;
}

.branchesHeader {
  border-bottom: 1px solid #a4a4a4;
  font-size: 12px;
  color: #404040;
  display: flex;
  margin-bottom: 10px;
}

.branchesHeader span {
  flex: 1;
  padding-left: 15px;
  display: block;
}

.branch {
  display: flex;
  margin-bottom: 25px;
}

.branch div {
  flex: 1;
  padding-left: 15px;
}

.actionBranch {
  display: flex;
  align-items: center;
}

.actionBranch span {
  display: block;
  margin-right: 5px;
}

.mainButton {
  display: block;
  font-family: Bree;
  font-weight: 700;
  color: var(--darkGray);
  background-color: var(--green);
  border-color: var(--green);
  font-size: 18px;
  line-height: 55px;
  height: 60px;
}

.mainButton:hover,
.mainButton:focus {
  border-color: var(--green);
  color: var(--darkGray);
  background-color: var(--green);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.wrapEmptyNewOrders {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: white;
}

.dayOrdersEmpty {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
  height: 200px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
}

.empty span {
  display: block;
  margin-top: 15px;
  font-weight: 500;
}

.wtx {
  font-family: Roboto;
  font-size: 14px;
}

.loyalty {
  margin-top: 20px;
}

.loyaltyInfo {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.switchWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 40px;
}

.input {
  background-color: #fbfbfb;
  border-color: #d2d2d2;
  border-radius: 4px;
}

.input input {
  background-color: transparent;
}

.tabsSummaryWrap {
  width: calc(100% - 2px);
}

.tipAvailable {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.tipAvailable .labelInfo {
  margin-right: 10px;
  margin-bottom: -10px;
}

@media only screen and (max-width: 992px) {
  .mainButton {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
  }
}
