.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header h1 {
  color: var(--green);
  font-family: Bree;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 0;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.section {
  background-color: white;
  padding: 40px 20px;
  border-radius: 6px;
  box-shadow: 0 2px 15px rgba(100, 100, 100, 0.15);
}

.sectionWrap .header {
  font-family: Bree;
  font-weight: 700;
  color: var(--darkGray);
  font-size: 16px;
  margin-bottom: 10px;
}

.banner {
  margin-bottom: 40px;
}

.bannerPhoto {
  height: 120px;
  background-position: center;
  background-size: cover;
  border: 1px solid #808080;
}

.bannerActions {
  padding: 0 10px;
}

.actionWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  border-bottom: 1px solid #c9c9c9;
  cursor: pointer;
  height: 40px;
}

.actionWrap:last-child {
  border-bottom: none;
}

.newBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid #d1d1d1;
  font-weight: 500;
  color: var(--darkGray);
  height: 50px;
  cursor: pointer;
}

.newBtn span {
  display: block;
  margin-left: 10px;
}

.innactive {
  opacity: 0.5;
}

.promosHeaderWrap {
  border-bottom: 1px solid #b1b1b1;
  margin-top: -15px;
  font-size: 12px;
}

.promosHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  font-weight: 500;
  color: var(--darkGray);
}

.promoTypeHeader {
  border-right: 1px solid #b1b1b1;
}

.promoList {
  padding: 20px;
  height: 100%;
}

.promoDetail {
  padding: 40px 60px;
}

.promoItem {
  padding: 40px 0;
  text-align: center;
  font-weight: 500;
  color: var(--darkGray);
  font-size: 16px;
  cursor: pointer;
}

.promoActive {
  display: flex;
  align-items: center;
}

.promoIcon {
  margin-right: 20px;
}

.activePromoName {
  flex: 1;
}

.labelName {
  color: var(--darkGray);
  display: block;
}

.promoName {
  font-family: Bree;
  color: var(--green);
  display: block;
  font-weight: 700;
  font-size: 20px;
}

.promoActiveInfo {
  padding: 40px 70px;
}

.promoInfoItem {
  margin-bottom: 40px;
}

.promoInfoItem span {
  display: block;
}

.promoInfoLabel {
  color: var(--darkGray);
  font-weight: 500;
}

.promoInfoValue {
  color: #7e7e7e;
}

.activeWrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #b1b1b1;
  padding-bottom: 20px;
  justify-content: flex-end;
  margin-right: -20px;
  color: var(--darkGray);
  font-weight: 500;
  font-size: 12px;
  position: relative;
}

.edit {
  color: var(--green);
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: -35px;
  cursor: pointer;
}

.activeLabel {
  color: var(--green);
  font-weight: 700;
}
